header{
  margin-top: 78px;
}

.header_container {
  text-align: center;
  height: 100vh;
  position: relative;
  margin-top: 8px;
}
.name {
  letter-spacing: 10px;
  font-size: 3.5em;
  text-shadow: 0px 0px 25px rgb(252, 248, 248);
}
.cta {
  margin-top: 1rem;
  display: flex;
  gap: 1.2rem;
  justify-content: center;
}

.me {
  background: linear-gradient(var(--color-primary), transparent);
  width: auto;
  height: 30rem;
  left: 50%;
  transform: translateX(-50%);
  overflow: hidden;
  border-radius: 5rem 5rem 0rem 0rem;

  box-shadow: 0 0 65px #030303;
}
/* .scroll_down {
  position: absolute;
  right: -2.3rem;
  bottom: 15rem;
  transform: rotate(90deg);
  font-weight: 300;
  font-size: 0.8rem;
} */

@media screen and (max-width: 1024px) {
  header {
    height: 68vh;
  }
}
@media screen and (max-width: 600px) {
  header {
    height: 100vh;
  }
  .scroll_down {
    display: none;
  }
}
@media screen and (max-width: 600px) {
  .text-light {
    letter-spacing: 1.1px;
    font-size: 1.3rem;
    text-align: center;
  }
  .name {
    letter-spacing: 1.8px;
    font-size: 2.1rem;
    text-align: center;
    display: contents;
  }
  .btn {
    display: none;
  }
}
