.text-light {
  text-align: center;
}
.text-light-about {
  text-align: center;
  margin-top: 5px;
  margin-bottom: 10px;
  margin-top: 10px;
}
.about_container {
  display: grid;
  grid-template-columns: 30%;
  gap: 1%;
  justify-content: center;
}

.about_text_div {
  display: flex;
  justify-content: center;
}

.about_text {
  max-width: 600px;
  text-align: center;
  font-size: 14px;
  margin-top: 25px;
  letter-spacing: 0.25px;
  padding: 10px;

  font-family: "Montserrat", sans-serif;
}
.about_container h2 {
  font-size: 32px;
  letter-spacing: 2px;
  text-shadow: 0px 0px 10px rgb(252, 252, 252);
}

.about_me {
  width: 90%;

  border-radius: 2rem;
  background: linear-gradient(45deg, transparent, var(--color-primary), transparent);
  place-items: center;

  margin-left: 26px;
  box-shadow: 0px 0px 20px rgb(252, 252, 252);
}
.about_me_img {
  border-radius: 2rem;
  overflow: hidden;
  transform: rotate(0deg);
}

.about_me_img:hover {
  border-radius: 2rem;
  overflow: hidden;
  transform: rotate(10deg);
  transition: var(--transition);
}
@media screen and (max-width: 850px) {
  .about_container {
    display: grid;
    grid-template-columns: 70%;
    /* gap: 5%; */
    justify-items: center;
  }
  .about_me {
    display: grid;
    border-radius: 2rem;
    background: linear-gradient(45deg, transparent, var(--color-primary), transparent);
    place-items: center;
    box-shadow: 0px 0px 20px rgb(252, 252, 252);
    margin-left: 0px;
  }
  .about_me_img {
    border-radius: 2rem;
    overflow: hidden;
    transform: rotate(0deg);
  }

  .about_me_img:hover {
    border-radius: 2rem;
    overflow: hidden;
    transform: rotate(10deg);
    transition: var(--transition);
  }
}
